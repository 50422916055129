const initialState = {
    // linkPantheonCompany: {false},
    // isMobile: false,
    // loading: false,
    // history: []
    // propertys added on user
    // profil_offers: true,
    // profil_customers: true,
    // profil_products: true,
    // profil_gratis: true
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LINK_PANTHEON_COMPANY': 
            return { ...state, linkPantheonCompany: action.payload }
        // case 'GET_COMPANY_PANTHEON':
        //     return { ...state, companyPantheon: action.payload }
        case 'LOGOUT':
            return { ...initialState }
        default: {
            return state;
        }
    }
}

export default appReducer;
import axios from '../../../utils/API';

// PROFIL CUSTOMERS API calls
// ------------------------------------------------------------------------------------------------
// CLOSE API
export async function getCustomers(dataDB) {
    return await axios.get('/pantheon/subject', { params: dataDB })
}

export async function getCustomersOffice(dataDB) {
    return await axios.get('/auth/user', { params: dataDB })
}

export async function addCustomerRoleOffice(dataDB) {
    return await axios.post('/auth/addUserRole', dataDB)
}

export async function removeCustomerRoleOffice(dataDB) {
    return await axios.post('/auth/removeUserRole', dataDB)
}

export async function updateCustomerCompanyData(dataDB) {
    return await axios.post('/auth/company', dataDB)
}

export async function getCompanyPantheon(dataDB) {
    return await axios.get('/auth/companyPantheon', { params: dataDB })
}

export async function linkCompanyPantheon(dataDB) {
    return await axios.post('/auth/companyPantheon', dataDB )
}

export async function getOrdersPantheon(dataDB) {
    return await axios.get('/pantheon/order', { params: dataDB })
}

export async function postOrdersPantheon(dataDB) {
    return await axios.post('/pantheon/order', dataDB )
}

export async function postOrderItemPantheon(dataDB) {
    return await axios.post('/pantheon/orderitems', dataDB )
}

export async function getStockPantheon(dataDB) {
    return await axios.get('/pantheon/stock', { params: dataDB })
}

export async function getPostPantheon(dataDB) {
    return await axios.get('/pantheon/postcode', { params: dataDB })
}

export async function sendPdf(acKey, email, alternateEmail) {
    if(alternateEmail) return await axios.get('/pantheon/send_pdf?acKey='+encodeURIComponent(acKey)+'&email='+encodeURIComponent(email)+'&email='+encodeURIComponent(alternateEmail))
    return await axios.get('/pantheon/send_pdf?acKey='+encodeURIComponent(acKey)+'&email='+encodeURIComponent(email))
}

export async function getPdf(dataDB) {
    return await axios.get('/pantheon/pdf', { params: dataDB })
}

export async function getPdfUuid(dataDB) {
    return await axios.get('/pantheon/generate_pdf_uuid', { params: dataDB })
}